<template>
  <div class="full-width full-height">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ResourceIndex',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
